const initialState = {
  isDarkMode: false,
  shoppingCart: [],
  isShowingCart: false,
  selectedProductCategory: "All",
}

//ACTION CREATOR
const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
const TOGGLE_CART = "TOGGLE_CART"
const ADD_TO_CART = "ADD_TO_CART"
const POPULATE_CART = "POPULATE_CART"
const REMOVE_FROM_CART = "REMOVE_FROM_CART"
const UPDATE_CART_COUNT = "UPDATE_CART_COUNT"
const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY"

export const toggleDarkMode = isDarkMode => ({
  type: TOGGLE_DARKMODE,
  isDarkMode,
})
export const toggleShowCart = isShowingCart => ({
  type: TOGGLE_CART,
  isShowingCart,
})

export const addToCart = newItem => ({
  type: ADD_TO_CART,
  newItem,
})
export const populateCart = cart => ({
  type: POPULATE_CART,
  cart,
})
export const updateCartCount = newShoppingCart => ({
  type: UPDATE_CART_COUNT,
  newShoppingCart,
})

export const removeFromCart = itemToRemove => ({
  type: REMOVE_FROM_CART,
  itemToRemove,
})

export const updateProductCategory = selectedCategory => ({
  type: UPDATE_PRODUCT_CATEGORY,
  selectedCategory,
})

//REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: action.isDarkMode }
    case TOGGLE_CART:
      return { ...state, isShowingCart: action.isShowingCart }
    case ADD_TO_CART:
      return { ...state, shoppingCart: [...state.shoppingCart, action.newItem] }
    case POPULATE_CART:
      return { ...state, shoppingCart: action.cart }
    case UPDATE_CART_COUNT:
      return { ...state, shoppingCart: action.newShoppingCart }
    case UPDATE_PRODUCT_CATEGORY:
      return {
        ...state,
        selectedProductCategory: action.selectedCategory,
      }
    default:
      return state
  }
}
