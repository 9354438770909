// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/opt/build/repo/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("/opt/build/repo/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-confirmation-js": () => import("/opt/build/repo/src/pages/order-confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-order-failed-js": () => import("/opt/build/repo/src/pages/order-failed.js" /* webpackChunkName: "component---src-pages-order-failed-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sale-js": () => import("/opt/build/repo/src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-shop-js": () => import("/opt/build/repo/src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-verify-transaction-js": () => import("/opt/build/repo/src/pages/verify-transaction.js" /* webpackChunkName: "component---src-pages-verify-transaction-js" */)
}

